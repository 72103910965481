.ant-pagination .ant-pagination-item-active a {
    color: rgba(0, 0, 0, 0.88);
}

.ant-pagination .ant-pagination-item-active a:hover {
    color: #000;
}

.link-hover:hover {
    text-decoration: underline !important;
}

/* Scroll */
/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Font */
@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Light'), local('TTFirsNeue-Light'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Regular'), local('TTFirsNeue-Regular'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Bold Italic'), local('TTFirsNeue-BoldItalic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-BoldItalic.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue DemiBold Italic'), local('TTFirsNeue-DemiBoldItalic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-DemiBoldItalic.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue ExtraLight'), local('TTFirsNeue-ExtraLight'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-ExtraLight.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Thin Italic'), local('TTFirsNeue-ThinItalic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-ThinItalic.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Medium Italic'), local('TTFirsNeue-MediumItalic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-MediumItalic.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue DemiBold'), local('TTFirsNeue-DemiBold'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-DemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Black Italic'), local('TTFirsNeue-BlackItalic'),
        x url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-BlackItalic.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Light Italic'), local('TTFirsNeue-LightItalic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-LightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue ExtraLight Italic'), local('TTFirsNeue-ExtraLightItalic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-ExtraLightItalic.ttf) format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Bold'), local('TTFirsNeue-Bold'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue ExtraBold'), local('TTFirsNeue-ExtraBold'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Medium'), local('TTFirsNeue-Medium'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Thin'), local('TTFirsNeue-Thin'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-Thin.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue ExtraBold Italic'), local('TTFirsNeue-ExtraBoldItalic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-ExtraBoldItalic.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Black'), local('TTFirsNeue-Black'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-Black.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Italic'), local('TTFirsNeue-Italic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'UK Number Plate';
    src: local('UK Number Plate Italic'), local('UKNumberPlate'),
        url(/public/assets/fonts/ukNumberPlate/UKNumberPlate.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium'), local('Roboto-Medium'),
        url(/public/assets/fonts/plateFontRoboto/Roboto-Medium.ttf) format('truetype');
}

/* Toast */
.Toastify__toast-body {
    padding-top: 0;
    padding-bottom: 0;
    font-family: 'TT Firs Neue';
}

.Toastify__toast--error {
    background-color: rgb(252, 231, 228);
    color: #e01b00;
    border-left: 2px solid #e01b00;
    position: relative;
}

/* .Toastify__toast--error::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: white;
} */

.Toastify__toast--success {
    background-color: rgba(232, 245, 233, 1);
    color: #009d4f;
    border-left: 2px solid #009d4f;
}

.Toastify__toast--warning {
    background-color: rgb(253 239 205);
    color: #ff8b00;
    border-left: 2px solid #ff8b00;
}

.Toastify__toast {
    min-height: 56px;
}

/* .zoom-m [data-rmiz-modal-overlay],
.zoom-m [data-rmiz-modal-img] {
    transition-duration: 0.8s;
    transition-timing-function: linear;
} */
/* .zoom-m [data-rmiz-modal-overlay='hidden'] {
    background-color: rgb(56, 58, 89, 0);
} */
.zoom-m [data-rmiz-modal-overlay='visible'] {
    background-color: rgba(0, 0, 0, 0.5);
}
/* .zoom-m [data-rmiz-btn-unzoom] {
    background-color: #bd93f9;
    color: #000;
} */
/* .zoom-m [data-rmiz-btn-unzoom]:focus-visible {
    outline-offset: 0.4rem;
    outline: 0.2rem solid #bd93f9;
} */
